import { useEffect, useState } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

export default function useFeatureFlags() {
    const [isLoading, setIsLoading] = useState(true);
    const [featureFlags, setFeatureFlags] = useState<{
        [key: string]: boolean;
    }>({});
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();

    useEffect(() => {
        const fetchFlags = async () => {
            const response = await authenticatedApi.get(
                `${orgId}/features/account`,
            );

            setIsLoading(false);
            const featureFlags = response.data;
            const flagsDictionary: { [key: string]: boolean } = {};

            for (let flag of featureFlags) {
                flagsDictionary[flag] = true;
            }
            console.log('Flags loaded', flagsDictionary);
            setFeatureFlags(flagsDictionary);
        };
        fetchFlags();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        featureFlags,
        isLoading,
    };
}
