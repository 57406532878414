import { useState } from 'react';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';

export interface Organization {
    id: number;
    name: string;
    logoUrl: string | null;
    createdAt: string;
    updatedAt: string;
}

export default function useOrganizations() {
    const authenticatedApi = useAuthenticatedApi();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const listOrganizations = async (): Promise<Organization[]> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get('/orgs');
            return response.data;
        } catch (err) {
            console.error('Error listing organizations:', err);
            setError('Failed to list organizations');
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    const getOrganization = async (
        id: number,
    ): Promise<Organization | null> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get(`/orgs/${id}`);
            return response.data;
        } catch (err) {
            console.error('Error getting organization:', err);
            setError('Failed to get organization');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        listOrganizations,
        getOrganization,
        isLoading,
        error,
    };
}
