import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTemplates, {
    Template,
    CreateTemplateRequest,
    UpdateTemplateRequest,
    TemplateType,
    templateTypeLabels,
} from '../hooks/useTemplates';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/src/shadComponents/ui/card';
import { Button } from '@/src/shadComponents/ui/button';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/src/shadComponents/ui/form';
import { Input } from '@/src/shadComponents/ui/input';
import { Textarea } from '@/src/shadComponents/ui/textarea';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/src/shadComponents/ui/select';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import Loading from '@/src/Common/components/Loading';
import CodeEditor from '@uiw/react-textarea-code-editor';

const formSchema = z.object({
    name: z.string().min(1, 'Name is required'),
    description: z.string().min(1, 'Description is required'),
    videoUrl: z.string().optional(),
    definitionJsonString: z.string().min(1, 'Template definition is required'),
    templateType: z.number().min(1, 'Template type is required'),
});

export default function TemplateForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        createTemplate,
        updateTemplate,
        isLoading,
        error: apiError,
        getTemplate,
    } = useTemplates();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [template, setTemplate] = useState<Template | null>(null);
    const [parsedTemplate, setParsedTemplate] = useState<any>(null);
    const [foundVariables, setFoundVariables] = useState<string[]>([]);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: '',
            description: '',
            videoUrl: '',
            definitionJsonString: '{}',
            templateType: TemplateType.Experience,
        },
    });

    useEffect(() => {
        const fetchTemplate = async () => {
            if (id) {
                // Fetch template details if editing
                // This would need to be implemented in the hook
                const data = await getTemplate(parseInt(id, 10));
                if (data) {
                    setTemplate(data);
                    form.reset({
                        name: data.name,
                        description: data.description,
                        videoUrl: data.videoUrl || '',
                        definitionJsonString: data.definitionJsonString,
                        templateType: data.templateType,
                    });
                }
            }
        };

        fetchTemplate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        try {
            if (id) {
                // Update existing template
                const updated = await updateTemplate(
                    parseInt(id, 10),
                    values as UpdateTemplateRequest,
                );
                if (updated) {
                    navigate('/admin/templates');
                }
            } else {
                // Create new template
                const created = await createTemplate(
                    values as CreateTemplateRequest,
                );
                if (created) {
                    navigate('/admin/templates');
                }
            }
        } catch (error) {
            console.error('Error submitting template:', error);
        }
    };

    useEffect(() => {
        try {
            let parsedTemplate =
                (form.getValues('definitionJsonString') &&
                    JSON.parse(form.getValues('definitionJsonString'))) ||
                null;
            setParsedTemplate(parsedTemplate);

            // look for variables in the template. The delimeter is <%= and %>
            const definitionJsonString = form.getValues('definitionJsonString');
            const variables = definitionJsonString.match(/<%=([^%>]+)%>/g);
            if (variables) {
                setFoundVariables(
                    variables.map((variable) =>
                        variable.replace(/<%=|%>/g, '').trim(),
                    ),
                );
            }
        } catch (error) {
            console.error('Error parsing template:', error);
            setParsedTemplate(null);
            setFoundVariables([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.getValues('definitionJsonString')]);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-2xl">
                    {id ? 'Edit Template' : 'Create Template'}
                </CardTitle>
                <CardDescription>
                    {id
                        ? 'Update your experience template'
                        : 'Create a new experience template'}
                </CardDescription>
            </CardHeader>
            <CardContent className="pb-20">
                {apiError && (
                    <div className="rounded-md bg-red-50 p-4 mb-4">
                        <div className="text-sm text-red-700">{apiError}</div>
                    </div>
                )}
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-8"
                    >
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormDescription>
                                        The name of your template
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="description"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Description</FormLabel>
                                    <FormControl>
                                        <Textarea {...field} />
                                    </FormControl>
                                    <FormDescription>
                                        A brief description of the template
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="videoUrl"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Video URL (Optional)</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormDescription>
                                        URL to a video demonstrating the
                                        template
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="definitionJsonString"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Template Definition</FormLabel>
                                    <div className="flex flex-row gap-2">
                                        <FormControl>
                                            <div className="h-[500px] overflow-auto">
                                                <CodeEditor
                                                    language="json"
                                                    {...field}
                                                    style={{
                                                        fontFamily:
                                                            'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                                    }}
                                                />
                                            </div>
                                        </FormControl>
                                        <FormDescription>
                                            <span className="text-sm text-gray-500 font-bold">
                                                Detected Template Variables:
                                            </span>
                                            <span className="text-sm text-gray-500">
                                                {parsedTemplate &&
                                                    parsedTemplate?.templateValues &&
                                                    Object.keys(
                                                        parsedTemplate?.templateValues,
                                                    ).map((key) => (
                                                        <div>
                                                            {key} (
                                                            {foundVariables.find(
                                                                (variable) => {
                                                                    console.log(
                                                                        variable,
                                                                        key,
                                                                    );
                                                                    return (
                                                                        variable ===
                                                                        key
                                                                    );
                                                                },
                                                            )?.length || 0}
                                                            )
                                                        </div>
                                                    ))}
                                            </span>
                                            <br />
                                            <span className="text-sm text-gray-500 font-bold">
                                                Undefined Variables:
                                            </span>
                                            <div className="text-sm text-gray-500">
                                                <span className="text-sm text-red-500">
                                                    {foundVariables.map(
                                                        (variable) => {
                                                            if (
                                                                parsedTemplate
                                                                    ?.templateValues[
                                                                    variable
                                                                ]
                                                            ) {
                                                                return null;
                                                            }

                                                            return (
                                                                <div>
                                                                    {variable}
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                </span>
                                                {foundVariables.filter(
                                                    (variable) =>
                                                        !parsedTemplate
                                                            ?.templateValues[
                                                            variable
                                                        ],
                                                ).length === 0 && (
                                                    <span className="text-sm text-green-500">
                                                        No undefined variables
                                                        found
                                                    </span>
                                                )}
                                            </div>
                                        </FormDescription>
                                    </div>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="templateType"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Template Type</FormLabel>
                                    <Select
                                        onValueChange={(value) =>
                                            field.onChange(parseInt(value, 10))
                                        }
                                        defaultValue={field.value.toString()}
                                    >
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select a template type" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {Object.entries(
                                                templateTypeLabels,
                                            ).map(([value, label]) => (
                                                <SelectItem
                                                    key={value}
                                                    value={value}
                                                >
                                                    {label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormDescription>
                                        Choose between Experience and Processing
                                        templates
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <div className="flex justify-end gap-4 fixed bg-white bottom-0 right-0 left-0 h-[70px] p-2 bg-white border-t border-gray-200">
                            <Button
                                type="button"
                                variant="outline"
                                className="py-2"
                                onClick={() => navigate('/admin/templates')}
                            >
                                Cancel
                            </Button>
                            <Button type="submit">
                                {id ? 'Update Template' : 'Create Template'}
                            </Button>
                        </div>
                    </form>
                </Form>
            </CardContent>
        </Card>
    );
}
