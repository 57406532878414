import { useState } from 'react';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';

export interface LicenseType {
    id: number;
    name: string;
    description: string;
    key: string;
    createdAt: string;
    updatedAt: string;
}

export default function useLicenseTypes() {
    const authenticatedApi = useAuthenticatedApi();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const listLicenseTypes = async (): Promise<LicenseType[]> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get('/licenses/types');
            return response.data;
        } catch (err) {
            console.error('Error listing license types:', err);
            setError('Failed to list license types');
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    const getLicenseType = async (id: number): Promise<LicenseType | null> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get(
                `/licenses/types/${id}`,
            );
            return response.data;
        } catch (err) {
            console.error('Error getting license type:', err);
            setError('Failed to get license type');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        listLicenseTypes,
        getLicenseType,
        isLoading,
        error,
    };
}
