import React, { useEffect, useState } from 'react';
import useTemplates, {
    Template,
    templateTypeLabels,
} from '../hooks/useTemplates';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/src/shadComponents/ui/card';
import { Button } from '@/src/shadComponents/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/src/shadComponents/ui/table';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Loading from '@/src/Common/components/Loading';
import moment from 'moment-timezone';
import { Badge } from '@/src/shadComponents/ui/badge';

export default function TemplateList() {
    const [templates, setTemplates] = useState<Template[]>([]);
    const { listTemplates, isLoading, error } = useTemplates();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTemplates = async () => {
            const data = await listTemplates();
            setTemplates(data);
        };

        fetchTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (error) {
        return (
            <div className="rounded-md bg-red-50 p-4 mt-4">
                <div className="text-sm text-red-700">{error}</div>
            </div>
        );
    }

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <Card>
            <CardHeader>
                <div className="flex items-center justify-between">
                    <div>
                        <CardTitle className="text-2xl">Templates</CardTitle>
                        <CardDescription>
                            Manage your experience and processing templates
                        </CardDescription>
                    </div>
                    <Button
                        onClick={() => navigate('/admin/templates/new')}
                        className="flex items-center gap-2"
                    >
                        <PlusIcon className="h-4 w-4" />
                        New Template
                    </Button>
                </div>
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Name</TableHead>
                            <TableHead>Type</TableHead>
                            <TableHead>Version</TableHead>
                            <TableHead>Created</TableHead>
                            <TableHead>Updated</TableHead>
                            <TableHead className="text-right">
                                Actions
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {templates.map((template) => (
                            <TableRow key={template.id}>
                                <TableCell className="font-medium">
                                    {template.name}
                                </TableCell>
                                <TableCell>
                                    <Badge
                                        variant={
                                            template.templateType === 1
                                                ? 'default'
                                                : 'secondary'
                                        }
                                    >
                                        {
                                            templateTypeLabels[
                                                template.templateType
                                            ]
                                        }
                                    </Badge>
                                </TableCell>
                                <TableCell>{template.version}</TableCell>
                                <TableCell>
                                    {moment(template.createdAt).format(
                                        'MM/DD/YYYY',
                                    )}
                                </TableCell>
                                <TableCell>
                                    {moment(template.updatedAt).format(
                                        'MM/DD/YYYY',
                                    )}
                                </TableCell>
                                <TableCell className="text-right">
                                    <Button
                                        variant="outline"
                                        onClick={() =>
                                            navigate(
                                                `/admin/templates/${template.id}`,
                                            )
                                        }
                                    >
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}
