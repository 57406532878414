import React, { useEffect, useState } from 'react';
import useAnnouncements, {
    Announcement,
    announcementTypeLabels,
} from '../hooks/useAnnouncements';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/src/shadComponents/ui/card';
import { Button } from '@/src/shadComponents/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/src/shadComponents/ui/table';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Loading from '@/src/Common/components/Loading';
import moment from 'moment-timezone';
import { Badge } from '@/src/shadComponents/ui/badge';

export default function AnnouncementList() {
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);
    const { listAnnouncements, isLoading, error } = useAnnouncements();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAnnouncements = async () => {
            const data = await listAnnouncements();
            setAnnouncements(data);
        };

        fetchAnnouncements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (error) {
        return (
            <div className="rounded-md bg-red-50 p-4 mt-4">
                <div className="text-sm text-red-700">{error}</div>
            </div>
        );
    }

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <Card>
            <CardHeader>
                <div className="flex items-center justify-between">
                    <div>
                        <CardTitle className="text-2xl">
                            Announcements
                        </CardTitle>
                        <CardDescription>
                            Manage your announcements and releases
                        </CardDescription>
                    </div>
                    <Button
                        onClick={() => navigate('/admin/announcements/new')}
                        className="flex items-center gap-2"
                    >
                        <PlusIcon className="h-4 w-4" />
                        New Announcement
                    </Button>
                </div>
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Title</TableHead>
                            <TableHead>Type</TableHead>
                            <TableHead>By</TableHead>
                            <TableHead>Created</TableHead>
                            <TableHead className="text-right">
                                Actions
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {announcements.map((announcement) => (
                            <TableRow key={announcement.id}>
                                <TableCell className="font-medium">
                                    {announcement.title}
                                </TableCell>
                                <TableCell>
                                    <Badge
                                        variant={
                                            announcement.type === 0
                                                ? 'default'
                                                : 'secondary'
                                        }
                                    >
                                        {
                                            announcementTypeLabels[
                                                announcement.type
                                            ]
                                        }
                                    </Badge>
                                </TableCell>
                                <TableCell>{announcement.by}</TableCell>
                                <TableCell>
                                    {moment(announcement.createdAt).format(
                                        'MM/DD/YYYY',
                                    )}
                                </TableCell>
                                <TableCell className="text-right">
                                    <Button
                                        variant="outline"
                                        onClick={() =>
                                            navigate(
                                                `/admin/announcements/${announcement.id}`,
                                            )
                                        }
                                    >
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}
