import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export default function useAssets() {
    const { get, post } = useAuthenticatedApi();
    const { orgId } = useUserOrg();

    const getAssets = async (folder: string = '') => {
        const response = await get(`/${orgId}/assets`);

        const { data } = response;

        return data;
    };

    const uploadAsset = async (values: { [key: string]: string }) => {
        const formData = new FormData();
        for (let value in values) {
            formData.append(value, values[value]);
        }

        const response = await post('/assets', formData);

        const { data } = response;

        return data;
    };

    return {
        getAssets,
        uploadAsset,
    };
}
