import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAnnouncements, {
    Announcement,
    announcementTypeLabels,
} from '../hooks/useAnnouncements';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/src/shadComponents/ui/card';
import { Button } from '@/src/shadComponents/ui/button';
import Loading from '@/src/Common/components/Loading';
import moment from 'moment-timezone';
import { Badge } from '@/src/shadComponents/ui/badge';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '@/src/shadComponents/ui/alert-dialog';

export default function AnnouncementDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [announcement, setAnnouncement] = useState<Announcement | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { getAnnouncement, deleteAnnouncement, isLoading, error } =
        useAnnouncements();

    useEffect(() => {
        const fetchAnnouncement = async () => {
            if (id) {
                const data = await getAnnouncement(parseInt(id, 10));
                setAnnouncement(data);
            }
        };

        fetchAnnouncement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleDelete = async () => {
        if (id) {
            const result = await deleteAnnouncement(parseInt(id, 10));
            if (result) {
                navigate('/admin/announcements');
            }
        }
    };

    if (error) {
        return (
            <div className="rounded-md bg-red-50 p-4 mt-4">
                <div className="text-sm text-red-700">{error}</div>
            </div>
        );
    }

    if (isLoading || !announcement) {
        return <Loading isLoading={true} />;
    }

    return (
        <>
            <Card>
                <CardHeader>
                    <div className="flex items-center justify-between">
                        <div>
                            <CardTitle className="text-2xl">
                                {announcement.title}
                            </CardTitle>
                            <CardDescription>
                                Created by {announcement.by} on{' '}
                                {moment(announcement.createdAt).format(
                                    'MM/DD/YYYY',
                                )}
                            </CardDescription>
                        </div>
                        <div className="flex space-x-2">
                            <Button
                                variant="outline"
                                onClick={() =>
                                    navigate(
                                        `/admin/announcements/${announcement.id}/edit`,
                                    )
                                }
                            >
                                Edit
                            </Button>
                            <Button
                                variant="destructive"
                                onClick={() => setShowDeleteDialog(true)}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="space-y-4">
                        <div>
                            <Badge
                                variant={
                                    announcement.type === 0
                                        ? 'default'
                                        : 'secondary'
                                }
                            >
                                {announcementTypeLabels[announcement.type]}
                            </Badge>
                        </div>
                        <div className="whitespace-pre-wrap">
                            {announcement.message}
                        </div>
                    </div>
                </CardContent>
            </Card>

            <AlertDialog
                open={showDeleteDialog}
                onOpenChange={setShowDeleteDialog}
            >
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Delete Announcement</AlertDialogTitle>
                        <AlertDialogDescription>
                            Are you sure you want to delete this announcement?
                            This action cannot be undone.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleDelete}>
                            Delete
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
}
