import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTemplates, { Template } from '../hooks/useTemplates';
import useLicenseTypes from '@/src/LicenseTypes/hooks/useLicenseTypes';
import useOrganizations from '@/src/Organizations/hooks/useOrganizations';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/src/shadComponents/ui/card';
import { Button } from '@/src/shadComponents/ui/button';
import Loading from '@/src/Common/components/Loading';
import moment from 'moment-timezone';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/src/shadComponents/ui/dialog';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/src/shadComponents/ui/select';
import { Badge } from '@/src/shadComponents/ui/badge';
import { Separator } from '@/src/shadComponents/ui/separator';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

export default function TemplateDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        deleteTemplate,
        isLoading: isTemplateLoading,
        error: templateError,
        getTemplate,
        assignToLicenseType,
        removeFromLicenseType,
        getLicenseTypeAssignments,
        assignToOrg,
        removeFromOrg,
        getOrgAssignments,
    } = useTemplates();
    const { listLicenseTypes } = useLicenseTypes();
    const { listOrganizations } = useOrganizations();
    const [template, setTemplate] = useState<Template | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [licenseTypes, setLicenseTypes] = useState<any[]>([]);
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [assignedLicenseTypes, setAssignedLicenseTypes] = useState<number[]>(
        [],
    );
    const [assignedOrgs, setAssignedOrgs] = useState<number[]>([]);
    const [selectedLicenseType, setSelectedLicenseType] = useState<string>('');
    const [selectedOrg, setSelectedOrg] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                const [
                    templateData,
                    licenseTypesData,
                    licenseAssignmentsData,
                    organizationsData,
                    orgAssignmentsData,
                ] = await Promise.all([
                    getTemplate(parseInt(id, 10)),
                    listLicenseTypes(),
                    getLicenseTypeAssignments(parseInt(id, 10)),
                    listOrganizations(),
                    getOrgAssignments(parseInt(id, 10)),
                ]);

                if (templateData) {
                    setTemplate(templateData);
                }
                if (licenseTypesData) {
                    setLicenseTypes(licenseTypesData);
                }
                if (licenseAssignmentsData) {
                    setAssignedLicenseTypes(licenseAssignmentsData);
                }
                if (organizationsData) {
                    setOrganizations(organizationsData);
                }
                if (orgAssignmentsData) {
                    setAssignedOrgs(orgAssignmentsData);
                }
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleDelete = async () => {
        if (id) {
            const deleted = await deleteTemplate(parseInt(id, 10));
            if (deleted) {
                navigate('/admin/templates');
            }
        }
        setShowDeleteDialog(false);
    };

    const handleAssignLicenseType = async () => {
        if (id && selectedLicenseType) {
            const success = await assignToLicenseType(
                parseInt(id, 10),
                parseInt(selectedLicenseType, 10),
            );
            if (success) {
                setAssignedLicenseTypes([
                    ...assignedLicenseTypes,
                    parseInt(selectedLicenseType, 10),
                ]);
                setSelectedLicenseType('');
            }
        }
    };

    const handleRemoveLicenseType = async (licenseTypeId: number) => {
        if (id) {
            const success = await removeFromLicenseType(
                parseInt(id, 10),
                licenseTypeId,
            );
            if (success) {
                setAssignedLicenseTypes(
                    assignedLicenseTypes.filter((id) => id !== licenseTypeId),
                );
            }
        }
    };

    const handleAssignOrg = async () => {
        if (id && selectedOrg) {
            const success = await assignToOrg(
                parseInt(id, 10),
                parseInt(selectedOrg, 10),
            );
            if (success) {
                setAssignedOrgs([...assignedOrgs, parseInt(selectedOrg, 10)]);
                setSelectedOrg('');
            }
        }
    };

    const handleRemoveOrg = async (orgId: number) => {
        if (id) {
            const success = await removeFromOrg(parseInt(id, 10), orgId);
            if (success) {
                setAssignedOrgs(assignedOrgs.filter((id) => id !== orgId));
            }
        }
    };

    if (templateError) {
        return (
            <div className="rounded-md bg-red-50 p-4 mt-4">
                <div className="text-sm text-red-700">{templateError}</div>
            </div>
        );
    }

    if (isTemplateLoading || !template) {
        return <Loading isLoading={true} />;
    }

    return (
        <>
            <Card>
                <CardHeader>
                    <div className="flex items-center justify-between">
                        <div>
                            <CardTitle className="text-2xl">
                                {template.name}
                            </CardTitle>
                            <CardDescription>Template Details</CardDescription>
                        </div>
                        <div className="flex gap-2">
                            <Button
                                variant="outline"
                                onClick={() =>
                                    navigate(
                                        `/admin/templates/${template.id}/edit`,
                                    )
                                }
                            >
                                Edit
                            </Button>
                            <Button
                                variant="destructive"
                                onClick={() => setShowDeleteDialog(true)}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="space-y-8">
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-sm font-medium">
                                    Description
                                </h3>
                                <p className="text-sm text-gray-500">
                                    {template.description}
                                </p>
                            </div>

                            {template.videoUrl && (
                                <div>
                                    <h3 className="text-sm font-medium">
                                        Video URL
                                    </h3>
                                    <a
                                        href={template.videoUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-sm text-blue-500 hover:text-blue-700"
                                    >
                                        {template.videoUrl}
                                    </a>
                                </div>
                            )}

                            <div>
                                <h3 className="text-sm font-medium">
                                    Template Type
                                </h3>
                                <p className="text-sm text-gray-500">
                                    {template.templateType}
                                </p>
                            </div>

                            <div>
                                <h3 className="text-sm font-medium">Version</h3>
                                <p className="text-sm text-gray-500">
                                    {template.version}
                                </p>
                            </div>

                            <div>
                                <h3 className="text-sm font-medium">Created</h3>
                                <p className="text-sm text-gray-500">
                                    {moment(template.createdAt).format(
                                        'MM/DD/YYYY hh:mm A',
                                    )}
                                </p>
                            </div>

                            <div>
                                <h3 className="text-sm font-medium">
                                    Last Updated
                                </h3>
                                <p className="text-sm text-gray-500">
                                    {moment(template.updatedAt).format(
                                        'MM/DD/YYYY hh:mm A',
                                    )}
                                </p>
                            </div>
                        </div>

                        <Separator />

                        <div className="space-y-4">
                            <div className="flex items-center justify-between">
                                <h3 className="text-lg font-medium">
                                    Organization Assignments
                                </h3>
                                <div className="flex items-center gap-2">
                                    <Select
                                        value={selectedOrg}
                                        onValueChange={setSelectedOrg}
                                    >
                                        <SelectTrigger className="w-[200px]">
                                            <SelectValue placeholder="Select organization" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {organizations
                                                .filter(
                                                    (org) =>
                                                        !assignedOrgs.includes(
                                                            org.id,
                                                        ),
                                                )
                                                .map((org) => (
                                                    <SelectItem
                                                        key={org.id}
                                                        value={org.id.toString()}
                                                    >
                                                        {org.name}
                                                    </SelectItem>
                                                ))}
                                        </SelectContent>
                                    </Select>
                                    <Button
                                        onClick={handleAssignOrg}
                                        disabled={!selectedOrg}
                                        size="sm"
                                    >
                                        <PlusIcon className="h-4 w-4 mr-1" />
                                        Assign
                                    </Button>
                                </div>
                            </div>
                            <div className="space-y-2">
                                {organizations
                                    .filter((org) =>
                                        assignedOrgs.includes(org.id),
                                    )
                                    .map((org) => (
                                        <div
                                            key={org.id}
                                            className="flex items-center justify-between p-2 rounded-md border"
                                        >
                                            <div className="flex items-center gap-2">
                                                <Badge>{org.name}</Badge>
                                            </div>
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                onClick={() =>
                                                    handleRemoveOrg(org.id)
                                                }
                                            >
                                                <XMarkIcon className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    ))}
                                {assignedOrgs.length === 0 && (
                                    <p className="text-sm text-gray-500">
                                        No organizations assigned
                                    </p>
                                )}
                            </div>
                        </div>

                        <Separator />

                        <div className="space-y-4">
                            <div className="flex items-center justify-between">
                                <h3 className="text-lg font-medium">
                                    License Type Assignments
                                </h3>
                                <div className="flex items-center gap-2">
                                    <Select
                                        value={selectedLicenseType}
                                        onValueChange={setSelectedLicenseType}
                                    >
                                        <SelectTrigger className="w-[200px]">
                                            <SelectValue placeholder="Select license type" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {licenseTypes
                                                .filter(
                                                    (lt) =>
                                                        !assignedLicenseTypes.includes(
                                                            lt.id,
                                                        ),
                                                )
                                                .map((lt) => (
                                                    <SelectItem
                                                        key={lt.id}
                                                        value={lt.id.toString()}
                                                    >
                                                        {lt.name}
                                                    </SelectItem>
                                                ))}
                                        </SelectContent>
                                    </Select>
                                    <Button
                                        onClick={handleAssignLicenseType}
                                        disabled={!selectedLicenseType}
                                        size="sm"
                                    >
                                        <PlusIcon className="h-4 w-4 mr-1" />
                                        Assign
                                    </Button>
                                </div>
                            </div>
                            <div className="space-y-2">
                                {licenseTypes
                                    .filter((lt) =>
                                        assignedLicenseTypes.includes(lt.id),
                                    )
                                    .map((lt) => (
                                        <div
                                            key={lt.id}
                                            className="flex items-center justify-between p-2 rounded-md border"
                                        >
                                            <div className="flex items-center gap-2">
                                                <Badge>{lt.name}</Badge>
                                            </div>
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                onClick={() =>
                                                    handleRemoveLicenseType(
                                                        lt.id,
                                                    )
                                                }
                                            >
                                                <XMarkIcon className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    ))}
                                {assignedLicenseTypes.length === 0 && (
                                    <p className="text-sm text-gray-500">
                                        No license types assigned
                                    </p>
                                )}
                            </div>
                        </div>

                        <Separator />

                        <div>
                            <h3 className="text-lg font-medium mb-4">
                                Template Definition
                            </h3>
                            <pre className="rounded-md bg-gray-100 p-4 overflow-auto text-sm max-h-[500px]">
                                <code>
                                    {JSON.stringify(
                                        JSON.parse(
                                            template.definitionJsonString,
                                        ),
                                        null,
                                        2,
                                    )}
                                </code>
                            </pre>
                        </div>
                    </div>
                </CardContent>
            </Card>

            <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Template</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to delete this template? This
                            action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button
                            variant="outline"
                            onClick={() => setShowDeleteDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button variant="destructive" onClick={handleDelete}>
                            Delete
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
}
