import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';
import { useState, useEffect } from 'react';

export default function usePhotoEngineerStats() {
    const [stats, setStats] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const authenticatedApi = useAuthenticatedApi();

    useEffect(() => {
        const fetchStats = async () => {
            setIsLoading(true);
            const response = await authenticatedApi.get(
                '/photo-engineers/signup-stats',
            );
            setStats(response.data);
            setIsLoading(false);
        };
        fetchStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { stats, isLoading };
}
