import ExperienceGraph from './ExperienceGraph';
import { useLocalStorage } from '../Common/components/hooks/useLocalStorage';
import { JsonEditor } from 'json-edit-react';

interface ExperienceEditorProps {
    screens: ScreenDefinition[];
    definition: Definition;
    onScreenSelected: (screenId: string) => void;
    onDefinitionChange: (definition: Definition) => void;
}

export default function ExperienceEditor({
    screens,
    definition,
    onScreenSelected,
    onDefinitionChange,
}: ExperienceEditorProps) {
    const [showJsonEditor, setShowJsonEditor] = useLocalStorage(
        'showJsonEditor',
        false,
    );

    let component: React.ReactNode = null;
    if (!screens || screens.length === 0) {
        return (
            <ScreenCard>
                <i className="bi bi-plus text-2xl cursor-pointer"></i>
                <div>Create Screen</div>
            </ScreenCard>
        );
    }

    console.log('Screen count', screens.length);

    if (!showJsonEditor) {
        component = (
            <ExperienceGraph
                screens={screens}
                onScreenSelected={onScreenSelected}
            />
        );
    } else {
        component = (
            <div className="w-full mx-auto flex justify-center">
                <JsonEditor
                    data={definition}
                    className="w-[calc(100vw)]"
                    maxWidth="85vw"
                    onUpdate={(data) => {
                        console.log('data', data);
                        onDefinitionChange(
                            data.newData as unknown as Definition,
                        );
                    }}
                />
            </div>
        );
    }

    return (
        <div className="">
            <div className="flex justify-end mb-2 px-6 w-full">
                <span className="isolate inline-flex rounded-md shadow-xs">
                    <button
                        type="button"
                        onClick={() => setShowJsonEditor(false)}
                        className={`relative inline-flex cursor-pointer items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-gray-300 ring-inset hover:bg-gray-50 focus:z-10 ${
                            !showJsonEditor ? 'bg-gray-50' : ''
                        }`}
                    >
                        Builder
                    </button>
                    <button
                        type="button"
                        onClick={() => setShowJsonEditor(true)}
                        className={`relative -ml-px inline-flex cursor-pointer items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-gray-300 ring-inset hover:bg-gray-50 focus:z-10 ${
                            showJsonEditor ? 'bg-gray-50' : ''
                        }`}
                    >
                        JSON
                    </button>
                </span>
            </div>
            {component}
        </div>
    );
}

export function ScreenCard({ children }: { children: React.ReactNode }) {
    return (
        <div className="overflow-hidden bg-[#EEEFEF] shadow-md sm:rounded-lg max-w-[280px]">
            <div className="flex flex-col items-center justify-center px-4 py-5 sm:p-6  h-[400px]">
                {children}
            </div>
        </div>
    );
}
