import { useState } from 'react';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';

export enum AnnouncementType {
    Release = 0,
    Announcement = 1,
}

export const announcementTypeLabels: Record<AnnouncementType, string> = {
    [AnnouncementType.Release]: 'Release',
    [AnnouncementType.Announcement]: 'Announcement',
};

export interface Announcement {
    id: number;
    title: string;
    message: string;
    type: AnnouncementType;
    by: string;
    hash: string;
    createdAt: string;
}

export interface CreateAnnouncementRequest {
    title: string;
    message: string;
    type: AnnouncementType;
    by: string;
}

export interface UpdateAnnouncementRequest {
    title?: string;
    message?: string;
    type?: AnnouncementType;
    by?: string;
    hash?: string;
}

export default function useAnnouncements() {
    const authenticatedApi = useAuthenticatedApi();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const createAnnouncement = async (
        data: CreateAnnouncementRequest,
    ): Promise<Announcement | null> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.post(
                '/announcements',
                data,
            );
            return response.data;
        } catch (err) {
            console.error('Error creating announcement:', err);
            setError('Failed to create announcement');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const updateAnnouncement = async (
        id: number,
        data: UpdateAnnouncementRequest,
    ): Promise<Announcement | null> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.put(
                `/announcements/${id}`,
                data,
            );
            return response.data;
        } catch (err) {
            console.error('Error updating announcement:', err);
            setError('Failed to update announcement');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const deleteAnnouncement = async (
        id: number,
    ): Promise<Announcement | null> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.delete(
                `/announcements/${id}`,
            );
            return response.data;
        } catch (err) {
            console.error('Error deleting announcement:', err);
            setError('Failed to delete announcement');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const listAnnouncements = async (): Promise<Announcement[]> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get('/announcements');
            return response.data;
        } catch (err) {
            console.error('Error listing announcements:', err);
            setError('Failed to list announcements');
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    const getAnnouncement = async (
        id: number,
    ): Promise<Announcement | null> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get(`/announcements/${id}`);
            return response.data;
        } catch (err) {
            console.error('Error getting announcement:', err);
            setError('Failed to get announcement');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        createAnnouncement,
        updateAnnouncement,
        deleteAnnouncement,
        listAnnouncements,
        getAnnouncement,
        isLoading,
        error,
    };
}
