import * as React from 'react';
import {
    Route,
    Navigate,
    useLocation,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';
import Accounts from './Accounts/components/Accounts';
import CreateAccount from './Accounts/components/CreateAccount';
import EditAccount from './Accounts/components/EditAccount';
import SetPassword from './Accounts/components/SetPassword';
import './App.css';
import AuthProvider from './Authentication/components/AuthProvider';
import Login from './Authentication/components/Login';
import PersistLogin from './Authentication/components/PersistLogin';
import RequireAuth from './Authentication/components/RequireAuth';
import Layout from './Common/components/Layout';
import Activations from './Activations/components/Activations';
import Home from './Home/components/Home';
import CreateActivation from './Activations/components/CreateActivation';
import EditActivation from './Activations/components/EditActivation';
import ActivationDashboard from './Activations/components/ActivationDashboard';
import EditGallery from './GalleryConfiguration/components/EditGallery';
import UploadToActivation from './Activations/components/UploadToActivation';
import Gallery from './Gallery/components/Gallery';
import DeviceKeys from './DeviceManagement/components/DeviceKeys';
import DeviceGroups from './DeviceManagement/components/DeviceGroups';
import CreateDeviceKey from './DeviceManagement/components/CreateDeviceKey';
import ShareManager from './Activations/components/ShareManager';
import UploadTemplate from './EmailTemplates/components/UploadTemplate';
import EmailTemplates from './EmailTemplates/components/Templates';
import GalleryPhoto from './Gallery/components/GalleryPhoto';
import PersonalGallery from './Gallery/components/PersonalGallery';
import ShareLanding from './Share/components/ShareLanding';
import FeatureFlags from './Features/components/FeatureFlags';
import EditFeatureFlag from './Features/components/EditFeatureFlag';
import CreateFeatureFlag from './Features/components/CreateFeatureFlag';
import EditRoleFeatureFlagAssociation from './Features/components/EditRoleFeatureFlagAssociation';
import V3Sync from './Activations/components/V3Sync';
import GalleryDirectory from './Gallery/components/GalleryDirectory';
import EditCrowdGallery from './GalleryConfiguration/components/EditCrowdGallery';
import CreateProcessingFlow from './Processing/components/CreateProcessingFlow';
import EditProcessingFlow from './Processing/components/EditProcessingFlow';
import { DBOfSmiles } from './Gallery/components/dbofsmiles/DBOfSmiles';
import { useEffect } from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { FavoritesGallery } from './Gallery/components/favorites/FavoritesGallery';
import SessionGallery from './Gallery/components/SessionGallery';
import SessionManager from './Activations/components/SessionManager';
import SharingStation from './Gallery/components/SharingStation';
import Slideshow from './Gallery/components/Slideshow';
import CreateDeviceGroup from './DeviceManagement/components/CreateDeviceGroup';
import CreateDeviceInvite from './DeviceManagement/components/CreateDeviceInvite';
import DeviceInvites from './DeviceManagement/components/DeviceInvites';
import GroupDeviceKeys from './DeviceManagement/components/GroupDeviceKeys';
import JobsDashboard from './Jobs/components/JobsDashboard';
import JobDetail from './Jobs/components/JobDetail';
import JobEditor from './Jobs/components/JobEditor';
import AdminDocumentFrame from './Documents/components/AdminDocumentFrame';
import PublicFeatureFlags from './Features/components/PublicFeatureFlags';
import 'react-day-picker/dist/style.css';
import CreateJob from './Jobs/components/CreateJob';
import DeviceManagement from './DeviceManagement/components/DeviceManagement';
import OrgSettings from './Orgs/components/OrgSettings';
import PicTimeRedirectHandler from './Orgs/components/PicTimeRedirectHandler';
import { CollageWall } from './Gallery/components/t4agt/CollageWall';
import { AnimatedCollageWall } from './Gallery/components/t4agt/AnimatedCollageWall';
import EditFTPSettings from './GalleryConfiguration/components/EditFTPSettings';
import OrgsList from './Orgs/components/OrgsList';
import OrgCreate from './Orgs/components/OrgCreate';
import OrgAdministration from './Orgs/components/OrgAdministration';
import OrgAccountCreate from './Orgs/components/OrgAccountCreate';
import PageNotFound from './Common/components/PageNotFound';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import persistedStore from '../src/ExperienceBuilder/utils/experienceStore';
import SingleDestinationActivationGallery from './Gallery/components/SingleDestinationActivationGallery';
import Loading from './Common/components/Loading';
import SystemHealth from './SystemHealth/SystemHealth';
import SessionCreate from './Activations/components/SessionCreate';
import PaymentLanding from './Gallery/components/payments/PaymentLandingPage';
import CreateLicenseType from './Licenses/components/CreateLicenseType';
import EditLicenseType from './Licenses/components/EditLicenseType';
import LicenseTypes from './Licenses/components/LicenseTypes';
import EditLicenseTypeFeatures from './Licenses/components/EditLicenseTypeFeatures';
import OrgLicenses from './Licenses/components/OrgLicenses';
import CreateLicense from './Licenses/components/CreateLicense';
import { DeviceTelemetryView } from './DeviceTelemetry';
import DeepLocal from './Gallery/components/DeepLocal';
import PhotoEngineerStats from './PhotoEngineers/components/PhotoEngineerStats';
import { TemplateList, TemplateForm, TemplateDetail } from './Templates';
import {
    AnnouncementDetail,
    AnnouncementForm,
    AnnouncementList,
} from './Announcements';

let { store, persistor } = persistedStore();

const ROLES = {
    Admin: 0,
    User: 1,
    Client: 2,
    GlobalAdmin: 69,
};

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            {/* @ts-ignore */}
            <Route element={<PublicFeatureFlags />}>
                <Route path="/gallery" element={<GalleryDirectory />} />
                <Route path="/db-of-smiles" element={<DBOfSmiles />} />
                <Route
                    path="/collage/:activationId"
                    element={<CollageWall />}
                />
                <Route
                    path="/animated-collage/:activationId"
                    element={<AnimatedCollageWall />}
                />
                <Route path="/gallery/:slug" element={<Gallery />} />
                <Route
                    path="/gallery/:slug/user/:destinationSlug"
                    element={<SingleDestinationActivationGallery />}
                />
                <Route
                    path="/gallery/:slug/sharing"
                    element={<SharingStation />}
                />
                <Route path="/gallery/:slug/dl" element={<DeepLocal />} />
                <Route
                    path="/gallery/:slug/session-payment/:token"
                    element={<PaymentLanding />}
                />
                <Route
                    path="/gallery/:slug/slideshow"
                    element={<Slideshow />}
                />
                <Route
                    path="/session/:galleryId/:photoSessionId"
                    element={<SessionGallery />}
                />
                <Route path="/photos/:slug" element={<PersonalGallery />} />
                <Route path="/photo/:galleryId" element={<GalleryPhoto />} />
                <Route
                    path="/gallery/:galleryId/share"
                    element={<ShareLanding />}
                />
                <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<PersistLogin />}>
                <Route
                    element={
                        <RequireAuth
                            allowedRoles={[
                                ROLES.Admin,
                                ROLES.User,
                                ROLES.GlobalAdmin,
                            ]}
                        />
                    }
                >
                    <Route path="/" element={<Layout />}>
                        <Route path="home" element={<Home />} />

                        <Route path="accounts" element={<Accounts />} />
                        <Route
                            path="favorites"
                            element={<FavoritesGallery />}
                        />
                        <Route path="accounts/:id" element={<EditAccount />} />
                        <Route
                            path="accounts/:id/password"
                            element={<SetPassword />}
                        />
                        <Route
                            path="accounts/create"
                            element={<CreateAccount />}
                        />
                        <Route path="activations" element={<Activations />} />
                        <Route
                            path="activations/create"
                            element={<CreateActivation />}
                        />

                        <Route path="activations/v3sync" element={<V3Sync />} />

                        <Route
                            path="activations/:id/edit"
                            element={<EditActivation />}
                        />

                        <Route
                            path="activations/:activationId"
                            element={<ActivationDashboard />}
                        />

                        <Route
                            path="activations/:id/upload"
                            element={<UploadToActivation />}
                        />

                        <Route
                            path="activations/:id/gallery/:galleryId/edit"
                            element={<EditGallery />}
                        />

                        <Route
                            path="activations/:activationId/sessions/create"
                            element={<SessionCreate />}
                        />

                        <Route
                            path="activations/:activationId/sessions/:photoSessionId"
                            element={<SessionManager />}
                        />

                        <Route
                            path="activations/:activationId/manage-shares"
                            element={<ShareManager />}
                        />

                        <Route
                            path="activations/:activationId/galleryConfiguration/:galleryId"
                            element={<EditGallery />}
                        />

                        <Route
                            path="activations/:activationId/gallery/:galleryId/edit/crowdGallery"
                            element={<EditCrowdGallery />}
                        />

                        <Route
                            path="activations/:activationId/gallery/:galleryId/edit/ftp"
                            element={<EditFTPSettings />}
                        />

                        <Route
                            path="activations/:activationId/gallery/:galleryId/processing/create"
                            element={<CreateProcessingFlow />}
                        />

                        <Route
                            path="activations/:activationId/gallery/:galleryId/processing/edit/:flowId"
                            element={<EditProcessingFlow />}
                        />

                        <Route
                            path="activations/:activationId/documents/:documentId"
                            element={<AdminDocumentFrame />}
                        />

                        <Route
                            path="device-management/device-groups"
                            element={<DeviceGroups />}
                        />

                        <Route
                            path="device-management/device-groups/create"
                            element={<CreateDeviceGroup />}
                        />

                        <Route
                            path="device-management/device-groups/:groupId/invites"
                            element={<DeviceInvites />}
                        />

                        <Route
                            path="device-management/device-invites/create"
                            element={<CreateDeviceInvite />}
                        />

                        <Route
                            path="device-management"
                            element={<DeviceManagement />}
                        />
                        <Route path="device-keys" element={<DeviceKeys />} />

                        <Route
                            path="device-management/device-groups/:groupId/device-keys"
                            element={<GroupDeviceKeys />}
                        />

                        <Route
                            path="device-management/device-groups/:groupId/device-keys/create"
                            element={<CreateDeviceKey />}
                        />

                        <Route
                            path="device-management/device-keys/create"
                            element={<CreateDeviceKey />}
                        />

                        <Route path="/templates" element={<EmailTemplates />} />

                        <Route
                            path="templates/import"
                            element={<UploadTemplate />}
                        />

                        <Route
                            path="admin/templates"
                            element={<TemplateList />}
                        />

                        <Route
                            path="admin/templates/new"
                            element={<TemplateForm />}
                        />

                        <Route
                            path="admin/templates/:id"
                            element={<TemplateDetail />}
                        />

                        <Route
                            path="admin/templates/:id/edit"
                            element={<TemplateForm />}
                        />

                        <Route
                            path="admin/features"
                            element={<FeatureFlags />}
                        />

                        <Route
                            path="admin/features/create"
                            element={<CreateFeatureFlag />}
                        />

                        <Route
                            path="admin/features/:id"
                            element={<EditFeatureFlag />}
                        />

                        <Route
                            path="admin/features/role/:name/:id"
                            element={<EditRoleFeatureFlagAssociation />}
                        />

                        <Route
                            path="admin/licenses"
                            element={<LicenseTypes />}
                        />

                        <Route
                            path="admin/licenses/create"
                            element={<CreateLicenseType />}
                        />

                        <Route
                            path="admin/licenses/:id"
                            element={<EditLicenseType />}
                        />

                        <Route
                            path="admin/licenses/:licenseTypeId/features"
                            element={<EditLicenseTypeFeatures />}
                        />

                        <Route
                            path="admin/licenses/org/:orgId"
                            element={<OrgLicenses />}
                        />

                        <Route
                            path="admin/licenses/create-license"
                            element={<CreateLicense />}
                        />

                        <Route path="admin/orgs" element={<OrgsList />} />

                        <Route
                            path="admin/orgs/:orgId"
                            element={<OrgAdministration />}
                        />

                        <Route
                            path="admin/orgs/create"
                            element={<OrgCreate />}
                        />

                        <Route
                            path="admin/orgs/:orgId/account/create"
                            element={<OrgAccountCreate />}
                        />

                        <Route
                            path="admin/system-health"
                            element={<SystemHealth />}
                        />

                        <Route
                            path="admin/photo-engineers/stats"
                            element={<PhotoEngineerStats />}
                        />

                        <Route
                            path="admin/announcements"
                            element={<AnnouncementList />}
                        />

                        <Route
                            path="admin/announcements/new"
                            element={<AnnouncementForm />}
                        />

                        <Route
                            path="admin/announcements/:id"
                            element={<AnnouncementDetail />}
                        />

                        <Route
                            path="admin/announcements/:id/edit"
                            element={<AnnouncementForm />}
                        />

                        <Route path="jobs" element={<JobsDashboard />} />

                        <Route path="jobs" element={<JobsDashboard />} />

                        <Route path="jobs/:id" element={<JobDetail />} />
                        <Route path="jobs/:id/edit" element={<JobEditor />} />

                        <Route path="jobs/create" element={<CreateJob />} />

                        <Route
                            path="jobs/:jobId/activation/create"
                            element={<CreateActivation />}
                        />

                        <Route path="org" element={<OrgSettings />} />

                        <Route
                            path="integrations/pic-time/redirect"
                            element={<PicTimeRedirectHandler />}
                        />

                        <Route
                            path="/orgs/:orgId/device-telemetry/:deviceId"
                            element={<DeviceTelemetryView />}
                        />

                        <Route path="/" element={<Navigate to="/home" />} />
                    </Route>
                </Route>
                <Route path="/" element={<Navigate to="/home" />} />

                <Route path="*" element={<PageNotFound />} />
            </Route>
        </>,
    ),
);

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AuthProvider>
                    <RouterProvider
                        router={router}
                        fallbackElement={<Loading isLoading={true} />}
                    />
                    <div className="App"></div>
                </AuthProvider>
            </PersistGate>
        </Provider>
    );
}

// eslint-disable-next-line
function FirebaseAnalytics() {
    const location = useLocation();
    useEffect(() => {
        const page_path = location.pathname + location.search;
        const analytics = getAnalytics();
        logEvent(analytics, 'page_view', { firebase_screen: page_path });
    }, [location]);
    return null;
}
