import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/src/shadComponents/ui/card';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/src/shadComponents/ui/form';
import { Input } from '@/src/shadComponents/ui/input';
import { Textarea } from '@/src/shadComponents/ui/textarea';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/src/shadComponents/ui/select';
import { Button } from '@/src/shadComponents/ui/button';
import useAnnouncements, {
    AnnouncementType,
    announcementTypeLabels,
} from '../hooks/useAnnouncements';
import Loading from '@/src/Common/components/Loading';

const formSchema = z.object({
    title: z.string().min(1, 'Title is required'),
    message: z.string().min(1, 'Message is required'),
    type: z.nativeEnum(AnnouncementType),
    by: z.string().min(1, 'Author is required'),
});

export default function AnnouncementForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        createAnnouncement,
        updateAnnouncement,
        getAnnouncement,
        isLoading,
        error,
    } = useAnnouncements();

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            title: '',
            message: '',
            type: AnnouncementType.Announcement,
            by: '',
        },
    });

    useEffect(() => {
        const fetchAnnouncement = async () => {
            if (id) {
                const announcement = await getAnnouncement(parseInt(id, 10));
                if (announcement) {
                    form.reset({
                        title: announcement.title,
                        message: announcement.message,
                        type: announcement.type,
                        by: announcement.by,
                    });
                }
            }
        };

        fetchAnnouncement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        const data = {
            ...values,
        };

        if (id) {
            const result = await updateAnnouncement(parseInt(id, 10), data);
            if (result) {
                navigate('/admin/announcements');
            }
        } else {
            const result = await createAnnouncement(data);
            if (result) {
                navigate('/admin/announcements');
            }
        }
    };

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-2xl">
                    {id ? 'Edit Announcement' : 'Create Announcement'}
                </CardTitle>
                <CardDescription>
                    {id
                        ? 'Update the announcement details'
                        : 'Create a new announcement'}
                </CardDescription>
            </CardHeader>
            <CardContent>
                {error && (
                    <div className="rounded-md bg-red-50 p-4 mb-4">
                        <div className="text-sm text-red-700">{error}</div>
                    </div>
                )}
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <FormField
                            control={form.control}
                            name="title"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Title</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="message"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Message</FormLabel>
                                    <FormControl>
                                        <Textarea {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="type"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Type</FormLabel>
                                    <Select
                                        onValueChange={(value) =>
                                            field.onChange(parseInt(value, 10))
                                        }
                                        defaultValue={String(field.value)}
                                    >
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select a type" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {Object.entries(
                                                announcementTypeLabels,
                                            ).map(([value, label]) => (
                                                <SelectItem
                                                    key={value}
                                                    value={value}
                                                >
                                                    {label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="by"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Author</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className="flex justify-end space-x-2">
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => navigate('/admin/announcements')}
                            >
                                Cancel
                            </Button>
                            <Button type="submit">
                                {id ? 'Update' : 'Create'}
                            </Button>
                        </div>
                    </form>
                </Form>
            </CardContent>
        </Card>
    );
}
