import PageWithSidebar from '@/src/Common/components/PageWithSidebar';
import usePhotoEngineerStats from './hooks/usePhotoEngineerStats';
export default function PhotoEngineerStats() {
    const { stats, isLoading } = usePhotoEngineerStats();

    return (
        <PageWithSidebar title="PhotoEngineer Stats" subtitle="Stats">
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <div>
                    <h2>Signup Stats</h2>
                    <p>Signup stats for the last day</p>
                    {stats && (
                        <div>
                            <p>Day: {stats.day}</p>
                            <p>Week: {stats.week}</p>
                            <p>Month: {stats.month}</p>
                            <p>Year: {stats.year}</p>
                        </div>
                    )}
                </div>
            )}
        </PageWithSidebar>
    );
}
