import Modal from '@/src/Common/components/Modal';
import useShare from '@/src/Share/components/hooks/useShare';
import { Dialog } from '@headlessui/react';
import { PlusIcon } from 'lucide-react';
import { useRef, useState } from 'react';
import { toast } from 'sonner';

export default function SharePrompt({
    shareIdentifiers,
    activationId,
    galleryId,
    onComplete,
}: {
    shareIdentifiers: string[];
    activationId: number;
    galleryId: number;
    onComplete: () => void;
}) {
    const cancelButtonRef = useRef(null);
    const [newDestination, setNewDestination] = useState('');
    const [isSending, setIsSending] = useState(false);
    const { createShare } = useShare();

    const createZipAndSend = async () => {
        if (!newDestination) {
            return;
        }

        await createShare({
            destination: newDestination,
            shareIdentifiers,
            activationId,
            galleryId,
            type: newDestination.includes('@') ? 0 : 1,
        });

        setIsSending(true);

        await toast.success('Shares Sent to ' + newDestination);
        setIsSending(false);
        onComplete();
    };

    return (
        <Modal cancelButtonRef={cancelButtonRef} onDismiss={onComplete}>
            <div>
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-[99999px] bg-black sm:mx-0 sm:h-10 sm:w-10">
                            <PlusIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                            />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900"
                            >
                                Send Shares to Email or Text
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Sends {shareIdentifiers.length} photos to
                                    the email or text message you provide and
                                    notify the recipient. Will only send a
                                    single message, but all photos will be added
                                    to recipient's gallery.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ml-12 mt-2 p-2">
                    <div>
                        <label
                            htmlFor="destination"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Email / Phone
                        </label>
                        <div className="mt-2">
                            <input
                                id="name"
                                name="name"
                                type="destination"
                                required
                                value={newDestination}
                                onChange={(e) =>
                                    setNewDestination(e.target.value)
                                }
                                className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    type="button"
                    className="mt-3 inline-flex w-full ml-4 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => onComplete()}
                    ref={cancelButtonRef}
                >
                    Cancel
                </button>
                <br />
                <button
                    onClick={createZipAndSend}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-800 hover:bg-black-50 sm:mt-0 sm:w-auto"
                    disabled={isSending}
                >
                    {isSending ? 'Sending...' : 'Send Shares'}
                </button>
            </div>
        </Modal>
    );
}
