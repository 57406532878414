import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import useLogout from '../../Authentication/components/hooks/useLogout';

import Menu from './Menu';
import useFeatureFlags from '../../Features/components/hooks/useFeatureFlags';
import FeatureFlagContext from '../../Features/components/FeatureFlagContext';
import OrgContext from '../../Authentication/components/OrgContext';
import AuthContext from '../../Authentication/components/AuthContext';
import { Toaster } from '@/src/shadComponents/ui/sonner';
import { useLocalStorage } from './hooks/useLocalStorage';
import FeatureFlag from '@/src/Features/components/FeatureFlag';
import { FeatureFlags } from '@/src/Features/lib/constants';
import LoadingIndicator from './LoadingIndicator';

export default function Layout() {
    const {
        auth: { orgs },
    } = useContext(AuthContext);
    console.log('orgs', orgs[0]);
    const [org, setOrg] = useLocalStorage('current-org', orgs?.[0]);

    useEffect(() => {
        const doesOrgExistForCurrentUser = orgs?.find(
            (o: any) => o.id === org?.id,
        );
        if (!doesOrgExistForCurrentUser) {
            //console.log('Org does not exist for current user');
            setOrg(orgs?.[0]);
        } else {
            //console.log('Org exists for current user');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgs]);

    const logout = useLogout();
    return (
        <OrgContext.Provider value={{ org, setOrg }}>
            <FeatureFlagWrapper logout={logout} setOrg={setOrg} />
        </OrgContext.Provider>
    );
}

function FeatureFlagWrapper({
    logout,
    setOrg,
}: {
    logout: () => void;
    setOrg: (org: Org | null) => void;
}) {
    const { featureFlags, isLoading } = useFeatureFlags();
    if (isLoading) {
        return (
            <div className="bg-[#FFFFFF] dark:bg-[#262C2C] mx-auto h-[100vh] text-black dark:text-white">
                <div className="grid place-items-center px-6 py-24 sm:py-32 lg:px-8 h-full">
                    <LoadingIndicator />
                </div>
            </div>
        );
    }
    return (
        <FeatureFlagContext.Provider value={featureFlags}>
            <FeatureFlag
                flag={FeatureFlags.HAS_PHOTOSTAT_ACCESS}
                fallback={() => {
                    return (
                        <div className="bg-[#FFFFFF] dark:bg-[#262C2C] mx-auto h-[100vh] text-black dark:text-white">
                            <div className="grid place-items-center px-6 py-24 sm:py-32 lg:px-8 h-full">
                                <div className="text-center">
                                    <p className="text-4xl ibm-plex-regular font-semibold text-black dark:text-white pb-10 flex justify-center"></p>
                                    <h1 className="mt-4 text-5xl font-semibold sm:text-7xl text-black dark:text-white">
                                        Uh oh!
                                    </h1>
                                    <p className="mt-6 lato-regular text-lg font-medium sm:text-xl/8 text-black dark:text-white">
                                        Looks like you're somewhere you
                                        shouldn't be. <br />
                                        Let's get you back to where you belong.
                                    </p>
                                    <div className="mt-10 flex items-center justify-center gap-x-6">
                                        <button
                                            className="dark-button underline"
                                            onClick={() => {
                                                window.location.href =
                                                    'https://app.photoengineers.com';
                                            }}
                                        >
                                            Go back home
                                        </button>
                                        <a
                                            href="mailto:support@photoengineers.com"
                                            className="text-sm font-semibold text-black dark:text-white"
                                        >
                                            Contact support{' '}
                                            <span aria-hidden="true">
                                                &rarr;
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            >
                <div className="bg-white min-h-[100vh]" vaul-drawer-wrapper="">
                    <div className="">
                        <header className="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10 bg-white">
                            <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                                <Menu
                                    logout={() => {
                                        setOrg(null);
                                        logout();
                                    }}
                                />
                            </div>
                        </header>
                        <main className=" py-0">
                            <div className="relative isolate overflow-hidden pt-16">
                                <Outlet />
                            </div>
                        </main>
                        <Toaster position="bottom-left" />
                    </div>
                </div>
            </FeatureFlag>
        </FeatureFlagContext.Provider>
    );
}
