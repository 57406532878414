import { useState } from 'react';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';

export enum TemplateType {
    Experience = 1,
    Processing = 2,
}

export const templateTypeLabels: Record<TemplateType, string> = {
    [TemplateType.Experience]: 'Experience',
    [TemplateType.Processing]: 'Processing Flow',
};

export interface Template {
    id: number;
    name: string;
    description: string;
    videoUrl: string | null;
    definitionJsonString: string;
    version: number;
    templateType: TemplateType;
    createdAt: string;
    updatedAt: string;
}

export interface CreateTemplateRequest {
    name: string;
    description: string;
    videoUrl?: string;
    definitionJsonString: string;
    templateType: TemplateType;
}

export interface UpdateTemplateRequest {
    name?: string;
    description?: string;
    videoUrl?: string;
    definitionJsonString?: string;
    templateType?: TemplateType;
}

export default function useTemplates() {
    const authenticatedApi = useAuthenticatedApi();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const createTemplate = async (
        data: CreateTemplateRequest,
    ): Promise<Template | null> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.post('/pe-templates', data);
            return response.data;
        } catch (err) {
            console.error('Error creating template:', err);
            setError('Failed to create template');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const updateTemplate = async (
        id: number,
        data: UpdateTemplateRequest,
    ): Promise<Template | null> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.put(
                `/pe-templates/${id}`,
                data,
            );
            return response.data;
        } catch (err) {
            console.error('Error updating template:', err);
            setError('Failed to update template');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const deleteTemplate = async (id: number): Promise<Template | null> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.delete(
                `/pe-templates/${id}`,
            );
            return response.data;
        } catch (err) {
            console.error('Error deleting template:', err);
            setError('Failed to delete template');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const listTemplates = async (): Promise<Template[]> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get('/pe-templates');
            return response.data;
        } catch (err) {
            console.error('Error listing templates:', err);
            setError('Failed to list templates');
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    const assignToOrg = async (
        templateId: number,
        orgId: number,
    ): Promise<boolean> => {
        setIsLoading(true);
        setError(null);
        try {
            await authenticatedApi.post(
                `/pe-templates/${templateId}/orgs/${orgId}`,
            );
            return true;
        } catch (err) {
            console.error('Error assigning template to org:', err);
            setError('Failed to assign template to organization');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const removeFromOrg = async (
        templateId: number,
        orgId: number,
    ): Promise<boolean> => {
        setIsLoading(true);
        setError(null);
        try {
            await authenticatedApi.delete(
                `/pe-templates/${templateId}/orgs/${orgId}`,
            );
            return true;
        } catch (err) {
            console.error('Error removing template from org:', err);
            setError('Failed to remove template from organization');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const assignToLicenseType = async (
        templateId: number,
        licenseTypeId: number,
    ): Promise<boolean> => {
        setIsLoading(true);
        setError(null);
        try {
            await authenticatedApi.post(
                `/pe-templates/${templateId}/license-types/${licenseTypeId}`,
            );
            return true;
        } catch (err) {
            console.error('Error assigning template to license type:', err);
            setError('Failed to assign template to license type');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const removeFromLicenseType = async (
        templateId: number,
        licenseTypeId: number,
    ): Promise<boolean> => {
        setIsLoading(true);
        setError(null);
        try {
            await authenticatedApi.delete(
                `/pe-templates/${templateId}/license-types/${licenseTypeId}`,
            );
            return true;
        } catch (err) {
            console.error('Error removing template from license type:', err);
            setError('Failed to remove template from license type');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const getTemplate = async (id: number): Promise<Template | null> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get(`/pe-templates/${id}`);
            return response.data;
        } catch (err) {
            console.error('Error getting template:', err);
            setError('Failed to get template');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const getLicenseTypeAssignments = async (id: number): Promise<number[]> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get(
                `/pe-templates/${id}/license-types`,
            );
            return response.data;
        } catch (err) {
            console.error('Error getting license type assignments:', err);
            setError('Failed to get license type assignments');
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    const getOrgAssignments = async (id: number): Promise<number[]> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get(
                `/pe-templates/${id}/orgs`,
            );
            return response.data;
        } catch (err) {
            console.error('Error getting organization assignments:', err);
            setError('Failed to get organization assignments');
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    return {
        createTemplate,
        updateTemplate,
        deleteTemplate,
        listTemplates,
        assignToOrg,
        removeFromOrg,
        assignToLicenseType,
        removeFromLicenseType,
        isLoading,
        error,
        getTemplate,
        getLicenseTypeAssignments,
        getOrgAssignments,
    };
}
